import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TermsOfService from './../../components/terms-of-service'
import TermsMenu from './../../components/terms-menu'

const IndexPage = () => (
  <Layout>
    <SEO
      title='Terms Of Service'
      keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
      pageType={'company_info'}
      flowType={'none'} />
    <TermsMenu />
    <TermsOfService />
  </Layout>
)

export default IndexPage
